html body {
  text-align: start;
}

html .pt-xl {
  padding-top: 150px !important;
}

@media screen and (min-width: 768px) {
  html .pt-xl {
    padding-top: 180px !important;
  }
}

@media screen and (min-width: 992px) {
  html .pt-xl {
    padding-top: 150px !important;
  }
}

.pb-xl {
  padding-bottom: 150px !important;
}

@media screen and (min-width: 768px) {
  .pb-xl {
    padding-bottom: 180px !important;
  }
}

@media screen and (min-width: 992px) {
  .pb-xl {
    padding-bottom: 150px !important;
  }
}

/* Header related CSS */
.m-header__nav .m-header__sub-nav,
.m-header__nav .m-header__sub-nav .cl__list {
  padding: 0;
}

@media screen and (max-width: 992px) {
  .m-header__nav {
    .m-header__sub-nav {
      .aem-Grid.aem-Grid--default--12 > .aem-GridColumn {
        &.cardlinks,
        &.cardarticle {
          float: left;
        }
      }
    }
  }
}

@media screen and (min-width: 992px) {
  .m-header__nav .m-header__sub-nav {
    padding-bottom: 3.25rem;
    padding-top: 2.875rem;
  }
  .m-header__nav .m-header__sub-nav .cardlinks.aem-GridColumn.aem-GridColumn--default--3 {
    padding: 0;
  }
}

@media screen and (min-width: 992px) and (max-width: 1024px) {
  .m-header__nav-items {
    margin-top: 0;
  }
}

/* print styles */
@media print {
  @page {
    margin: 25mm;
    size: portrait;
  }

  body {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
  body > *:not(.print-content),
  body .print-content .no-print {
    display: none;
  }
}

.grid-layout-container {
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100vh;
}

html:dir(rtl) .icon-external-arrow:before {
  transform: rotate(-90deg);
}
